<template>
  <li v-if="header" class="nav-item-header mt-0"><div class="text-uppercase font-size-xs line-height-xs">{{ $t(header) }}</div> <i class="icon-menu" title="{{header}}"></i></li>
  <li @click="toggleMobileSideBar" class="nav-item nav-item-submenu">
    <router-link v-bind:to="uri" class="nav-link" active-class="active">
      <i v-bind:class="icon"></i>
      <span>{{ $t(title) }}<span v-if="subtitle" class="d-block font-weight-normal opacity-50">{{ $t(subtitle) }}</span></span>
    </router-link>

    <SideBarSubItemGroup v-if="items && items.length > 0" :items="items" :parent-title="title">
      <template v-slot:menuitem="slotProps">
        <side-bar-item :key="slotProps.title"
                       :title="slotProps.title"
                       :icon="slotProps.icon"
                       :uri="slotProps.uri" />
      </template>
    </SideBarSubItemGroup>
  </li>

<!--  <li class="nav-item-divider"></li>-->
</template>

<script>
import SideBarSubItemGroup from "@/components/layout/sidebar/navigation/SideBarSubItemGroup";
import {SideBarMixin} from "@/mixins/ViewMixin";
export default {
  name: "SideBarItem",
  mixins: [SideBarMixin],
  props: {
    icon: {
      type: String,
      default: ""
    },
    header: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    subtitle: {
      type: String,
      default: "",
    },
    uri: {
      type: Object,
      default: null,
    },
    items: {
      type: Array,
      default: function () {
        return [];
      }
    }
  },
  components: {SideBarSubItemGroup}
}
</script>

<style scoped>

</style>