<template>
  <div class="card card-sidebar-mobile">

    <!-- Header -->
    <div class="card-header header-elements-inline">
      <h6 class="card-title">{{ $t(title) }}</h6>
      <div class="header-elements">
        <div class="list-icons">
          <a class="list-icons-item" data-action="collapse"></a>
        </div>
      </div>
    </div>

    <!-- User menu -->
    <UserSideBar v-if="hasUserInfo"/>
    <!-- /user menu -->


    <!-- Main navigation -->
    <div class="card-body p-0">
      <ul class="nav nav-sidebar" data-nav-type="accordion">
        <SideBarItem v-for="item in items"
          :key="item.title"
          :title="item.title"
          :icon="item.icon"
          :uri="item.uri"
          :items="item.items || []"
        />
      </ul>
    </div>
    <!-- /main navigation -->

  </div>
</template>

<script>
import SideBarItem from "@/components/layout/sidebar/navigation/SideBarItem";
import UserSideBar from "@/components/user/UserSideBar";
export default {
  name: "SideBarCard",
  props: {
    title: String,
    hasUserInfo: Boolean,
    items: Array
  },
  components: {UserSideBar, SideBarItem}
}
</script>

<style scoped>

</style>