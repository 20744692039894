<template>
  <div class="sidebar sidebar-dark sidebar-main sidebar-expand-md align-self-start">

    <!-- Sidebar mobile toggler -->
    <SidebarMobileToggler />
    <!-- /sidebar mobile toggler -->


    <!-- Sidebar content -->
    <div class="sidebar-content">
      <SideBarCard
        :title="'general.breadcrumb.administration'"
        :items="items"
      />
    </div>
    <!-- /sidebar content -->

  </div>
</template>

<script>
import SidebarMobileToggler from "@/components/layout/sidebar/SidebarMobileToggler";
import SideBarCard from "@/components/layout/sidebar/SideBarCard";
import {AuthorizedActionsMixin} from "@/mixins/GeneralMixin";

export default {
  name: 'SideBar',
  components: {SideBarCard, SidebarMobileToggler},
  mixins: [AuthorizedActionsMixin],
  props: {
    msg: String
  },
  data: function () {
    return {
      administrationItems: [
        {
          icon: "icon-home4",
          title: "general.breadcrumb.home",
          uri: {name: 'general.breadcrumb.dashboard'},
          roles: ['super-admin', 'admin', 'user', 'farmer', 'driver'],
        },
        {
          icon: "icon-cart5",
          title: "general.breadcrumb.orders.list",
          uri: {name: 'general.breadcrumb.orders.list'},
          roles: ['super-admin', 'admin', 'user'],
        },
        {
          icon: "icon-store2",
          title: "general.breadcrumb.batches.list",
          uri: {name: 'general.breadcrumb.batches.list'},
          roles: ['super-admin', 'admin', 'user', 'farmer'],
        },
        {
          icon: "icon-balance",
          title: "general.breadcrumb.farmers.list",
          uri: {name: 'general.breadcrumb.farmers.list'},
          roles: ['super-admin', 'admin', 'user'],
        },
        {
          icon: "icon-user",
          title: "general.breadcrumb.users.list",
          uri: {name: 'general.breadcrumb.users.list'},
          roles: ['super-admin', 'admin'],
        },
        {
          icon: "icon-truck",
          title: "general.breadcrumb.cycles.list",
          uri: {name: 'general.breadcrumb.cycles.list'},
          roles: ['super-admin', 'admin'],
        },
        {
          icon: "icon-notebook",
          title: "general.breadcrumb.deliverynotes.list",
          uri: {name: 'general.breadcrumb.deliverynotes.list'},
          roles: ['super-admin', 'admin', 'user', 'driver'],
        },
        {
          icon: "icon-bell2",
          title: "general.breadcrumb.notifications.list",
          uri: {name: 'general.breadcrumb.notifications.list'},
          roles: ['super-admin', 'admin', 'user', 'farmer'],
        },
        {
          icon: "icon-barcode2",
          title: "general.breadcrumb.stocks.batches.list",
          uri: {name: 'general.breadcrumb.stocks.batches.list'},
          roles: ['super-admin', 'admin', 'user', 'farmer'],
        },
      ],
    }
  },
  computed: {
    items: function () {
      return this.administrationItems.filter((item) => this.hasOrAccess(item.roles));
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
