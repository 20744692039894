<template>
  <div class="sidebar-user">
    <div class="card-body">
      <div class="media">
        <div class="mr-3">
          <i class="icon-user-check icon-2x" />
        </div>

        <div class="media-body">
          <div class="media-title font-weight-semibold">{{ loggedInUser && loggedInUser.getName() }}</div>
          <div class="font-size-xs opacity-50">
            <i class="icon-pin font-size-sm"></i> &nbsp;Santa Ana, CA
          </div>
        </div>

        <div class="ml-3 align-self-center">
          <a href="#" class="text-white"><i class="icon-cog3"></i></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {AuthorizationMixin} from "@/mixins/GeneralMixin";

export default {
  name: "UserSideBar",
  mixins: [AuthorizationMixin]
}
</script>

<style scoped>

</style>