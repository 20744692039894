<template>
  <div class="sidebar-mobile-toggler text-center">
    <a @click="toggleMobileSideBar" class="sidebar-mobile-main-toggle cursor-pointer">
      <i class="icon-arrow-left8"></i>
    </a>
    <span class="font-weight-semibold">{{ $t('general.sidebar.title') }}</span>
    <a class="sidebar-mobile-expand"></a>
  </div>
</template>

<script>
import {SideBarMixin} from "@/mixins/ViewMixin";

export default {
  name: "SidebarMobileToggler",
  mixins: [SideBarMixin],
}
</script>

<style scoped>

</style>