<template>
  <ul class="nav nav-group-sub" v-bind:data-submenu-title="$t(parentTitle)">
    <slot name="menuitem" v-for="item in items"
                 :key="item.title"
                 :title="item.title"
                 :icon="item.icon"
                 :uri="item.uri">
    </slot>
  </ul>
</template>

<script>
export default {
  name: "SideBarSubItemGroup",
  components: {},
  props: {
    items: {
      type: Array,
      default: function () {
        return [];
      }
    },
    parentTitle: {
      type: String,
      default: "",
    }
  }
}
</script>

<style scoped>

</style>